import { Identifier } from 'react-admin'
import { CustomI18NITF } from '@activate-inc/activate-ui/dist/types/common.types'
import { PanelChoice } from '@/types/panel'
import { RaRecord } from 'ra-core'

export type ThemeName = 'light' | 'dark'

export interface UserPersonalITF {
  DisplayName: CustomI18NITF
  FullName: CustomI18NITF
  SortName: CustomI18NITF
}

export interface UserITF {
  p8Id: string
  acId: string
  UserToken: string
  UserName: string
  NickName: CustomI18NITF
  CreatedDate: number
  IsVisitor: boolean
  ClientKey: string
  LandingSource: string
  AcquireChannel: string
  UserPersonal: UserPersonalITF
  UserContext: {
    CurrentGroupId: string
    MemberRoleInGroup: string
    PermissionsHash: Record<any, string>
    AccessBuildingIds: string[]
  }
}

export type QRCodConfigContextValue = {
  getOptionValue: (name: string) => string
  setOptionValue: (name: string, value: string | boolean | QRCodeType) => void
}

export type SetOnSave = (onSave?: (values: object, redirect: any) => void) => void

export interface Category extends RaRecord {
  name: string
}

export interface Product extends RaRecord {
  category_id: Identifier
  description: string
  height: number
  image: string
  price: number
  reference: string
  stock: number
  thumbnail: string
  width: number
}

export interface Customer extends RaRecord {
  first_name: string
  last_name: string
  address: string
  stateAbbr: string
  city: string
  zipcode: string
  avatar: string
  birthday: string
  first_seen: string
  last_seen: string
  has_ordered: boolean
  latest_purchase: string
  has_newsletter: boolean
  groups: string[]
  nb_commands: number
  total_spent: number
}

export type OrderStatus = 'ordered' | 'delivered' | 'cancelled'

export interface Order extends RaRecord {
  status: OrderStatus
  basket: BasketItem[]
  date: Date
  total: number
}

export interface BasketItem {
  product_id: Identifier
  quantity: number
}

export interface Invoice extends RaRecord {}

export type ReviewStatus = 'accepted' | 'pending' | 'rejected'

export interface Review extends RaRecord {
  date: string
  status: ReviewStatus
  customer_id: Identifier
  product_id: Identifier
}

export enum QRCodeType {
  Account = 'Account',
  Property = 'Property',
  Activate = 'Activate',
  Custom = 'Custom',
}
export enum QRStatusType {
  Draft = 'Draft',
  Active = 'Active',
  PendingApproval = 'PendingApproval',
}

export enum QRCodeFunctionType {
  Announcements = 'Announcements',
  Communication = 'Communication',
  EmbeddedStaticUrl = 'EmbeddedStaticUrl',
  Emergency = 'Emergency',
  Feedback = 'Feedback',
  CustomerFeedback = 'CustomerFeedback',
  FieldEvent = 'FieldEvent',
  FieldMarketing = 'FieldMarketing',
  FrontDesk = 'FrontDesk',
  GoogleMap = 'GoogleMap',
  Html = 'Html',
  JoinWifi = 'JoinWifi',
  Maintenance = 'Maintenance',
  MoveIn = 'MoveIn',
  MoveOut = 'MoveOut',
  PDF = 'PDF',
  EmbeddedFile = 'EmbeddedFile',
  Portfolio = 'Portfolio',
  Purchase = 'Purchase',
  QrHub = 'QrHub',
  Renewal = 'Renewal',
  SimpleForm = 'SimpleForm',
  SocialMedia = 'SocialMedia',
  Static = 'StaticUrl',
  SupplierCheckin = 'SupplierCheckin',
  Support = 'Support',
  Vacancy = 'Vacancy',
  Video = 'Video',
  PhoneCall = 'PhoneCall',
  DownloadApp = 'DownloadApp',
  FireExtinguisher = 'FireExtinguisher',
  AEDInspection = 'AEDInspection',
  BathroomService = 'BathroomService',
  LeadCapture = 'LeadCapture',
  EVRequest = 'EVRequest',
  CheckIn = 'CheckIn',
  RoomRental = 'RoomRental',
  OpenAI = 'OpenAI',
  NewSupplier = 'NewSupplier',
  EquipmentInspection = 'EquipmentInspection',
  AngusServiceRequest = 'AngusServiceRequest',
  Inspection = 'Inspection',
  RoundInspection = 'RoundInspection',
  AngusOnDemandWorkOrder = 'AngusOnDemandWorkOrder',
  CommentCard = 'CommentCard',
  GuestCheckin = 'GuestCheckin',
  ResidentCheckIn = 'ResidentCheckIn',
  StudentCheckin = 'StudentCheckin',
  ServiceRequests = 'ServiceRequests',
  MechanicalSpace = 'MechanicalSpace',
  Inventory = 'Inventory',
  ActivitySchedule = 'ActivitySchedule',
  EventRegistration = 'EventRegistration',
  RoundReport = 'RoundReport',
  TenantHealth = 'TenantHealth',
  TenantPerformance = 'TenantPerformance',
  SupplierCheckReport = 'SupplierCheckReport',
  FeedbackLocationReport = 'FeedbackLocationReport',
  LadderSafety = 'LadderSafety',
  BurnPermit = 'BurnPermit',
  Permit = 'Permit',
  BEServiceRequest = 'BEServiceRequest',
  MoveOutResident = 'MoveOutResident',
  MaintenanceRequest = 'MaintenanceRequest',
  RoofAccess = 'RoofAccess',
  TenantHVAC = 'TenantHVAC',
  EquipmentLookup = 'EquipmentLookup',
  CleaningRoundReport = 'CleaningRoundReport',
  SupplierList = 'SupplierList',
  ResidentCheckOut = 'ResidentCheckOut',
  BathroomServiceReport = 'BathroomServiceReport',
  PortfolioInspectionDashboard = 'PortfolioInspectionDashboard',
  BuildingInspectionDashboard = 'BuildingInspectionDashboard',
  StaffCheckIn = 'StaffCheckIn',
  TourCheckin = 'TourCheckin',
  RoofAccessCarousel = 'RoofAccessCarousel',
  SecurityPatrolDashboard = 'SecurityPatrolDashboard',
}

export enum QRCodeConfigEnum {
  QRType = 'qrtype',
  BGColor = 'bgColor',
  EyeColor = 'eyeColor',
  ECLevel = 'eclevel',
  FGColor = 'fgColor',
  FillColor = 'fillColor',
  Size = 'size',
  StripeText = 'stripeText',
  LogoImage = 'logoImage',
  hasLogo = 'hasLogo',
  EyeFrameShape = 'eye',
  EyeBallShape = 'eyeball',
  BodyShape = 'body',
  BackgroundDimming = 'backgroundDimming',
  FileType = 'fileType',
  QrStyle = 'QrStyle',
}

export type LangType = {
  en?: string
  zh?: string
} & {
  [key: string]: string
}

export interface IBuilding {
  BuildingId: string
  BuildingName: LangType
  Score: number
}

declare global {
  interface Window {
    restServer: any
  }

  interface IOption {
    label: string
    value: string | number
    child?: IOption[]
    [key: string]: any
  }
  interface ISelectOption {
    [key: string]: any
  }
}

declare module '@activate-inc/activate-ui'

export interface IEntity {
  id: string
  BuildingId: string
  BuildingType: string
  Code: string
  GroupId: string
  Name: LangType
  Status: string
  Type: string
  acId: string
  LogoFilename?: string
  PropertyTag?: Record<string, any>
}

export interface IEntityType {
  EntityType: string
  Name: string
  Score: number | 'NA'
}

export interface IBuildingSummary {
  BuildingId: string
  BuildingName: LangType
  EntityTypes: IEntityType[]
  Score: number
}

export interface IEntitiesReport {
  AverageScore: number
  Name: string
  ScoredNumber: number
  TotalScore: string
  EntityType?: string
  First: { Trend: number }
  Second: { Trend: number }
  Third: { Trend: number }
}

export interface ITopic {
  NegativeScoreCount: number
  PositiveScoreCount: number
  TotalScoreCount: number
  Topic: string
}

export interface ResponseTopics {
  Topics: ITopic[]
}

export interface IComment {
  EntityId: string
  EntityType: string
  FeedbackId: string
  Score: number
  Text: string
  SubmitDate: number
  acId: string
  CreatedDate: number
}

export interface ResponseComment {
  Comments: IComment[]
}

export interface IWordCloud {
  Label: string
  Value: number
  SentimentScore: number
}

export interface IStarBucket {
  Score: number
  Count: number
}

export interface IEntityTypeAnalytic {
  BuildingId: string
  AverageScore: number
  StarBuckets: IStarBucket[]
}

export type PictureUploadedInfo = {
  location: string
}

export interface PictureManagerInterface {
  readonly region?: string
  readonly accessKeyId?: string
  readonly secretAccessKey?: string
  uploadImage: (username: string, file: File) => Promise<PictureUploadedInfo>
  deleteImage: (username: string, pictureUrl: string) => Promise<void>
  deleteUserFolder: (username: string) => Promise<void>
}

export interface IHubQrCode {
  id: string
  QrId: string
  HubId: string
  Name: string
  ImageUrl: string
  PointingUrl: string
  BannerUrl: string
}

export enum QRCodeFrame {
  NONE = 'none',
  BOX_BOTTOM = 'box-bottom',
  BOX_TOP = 'box-top',
  BANNER_TOP = 'banner-top',
  BANNER_BOTTOM = 'banner-bottom',
  BALLOON_BOTTOM = 'balloon-bottom',
  BALLOON_TOP = 'balloon-top',
  CIRCULAR = 'circular',
  TEXT_ONLY = 'text-only',
  FOCUS = 'focus',
  // BOX_LIGHT = 'box-light',
}

export interface ChoiceITF extends PanelChoice {
  Label: string
  Text: CustomI18NITF
  Value: number
}

export const HubItemStage = [
  { label: 'QR Engineering', value: 'EngineerReview' },
  { label: 'CS Review', value: 'CSReview' },
  { label: 'Client Review', value: 'ClientReview' },
  { label: 'Approved', value: 'Approved' },
]
