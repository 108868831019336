import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import { CheckBoxOutlineBlank, CheckBoxSharp, Event, AccessTime } from '@mui/icons-material'
import DateFnsUtils from '@date-io/date-fns'
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers'
import useSetState from '@/hooks/useSetState'
import { isCanSavaQr } from '@/utils'
import FieldType from '@/utils/FieldType'
import { QRCodeFunctionType } from '@/types'
import WithDefaultData from '@/hoc/WithDefaultData'
import { Checkbox, FormControlLabel, Radio, RadioGroup, Typography, InputAdornment, Switch } from '@mui/material'
import classnames from 'classnames'
import SnackBar from '@component/SnackBar'
import DragFormPanel from '@component/Form/Form/DragFormPanel'
import RichTextEdit from '@component/Form/richText'
import DragUploadFile, { DragUploadSuccessFile } from '@component/Form/DragUploadFile'

const language = 'en'
const onlyMoveArr = ['Q001', 'Q002', 'Q003', 'Q004', 'Q005']

type FieldItem = typeof FieldType.DropDownSelection

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
    },
    date: {
      margin: '20px 0 0',
    },
    singleCheckBoxRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: 8,
      paddingBottom: 8,
      borderBottom: '1px solid #D5D5D5',
    },
    noBorder: {
      paddingBottom: 0,
      borderBottomWidth: 0,
    },
    checkBox: {
      fontSize: 14,
      color: '#81858D',
    },
    attendance: {
      marginTop: 16,
      color: '#000000',
      fontFamily: 'Roboto, Regular',
      fontSize: 16,
    },
    switch: {},
  }
})

interface Props {
  onChange?: (staticForm: any) => void
  value: any
  functionType: QRCodeFunctionType
  disabledEdit?: boolean
}

const dateFns = new DateFnsUtils()

const EventPanel: React.FC<Props> = ({ onChange, value, disabledEdit = false }) => {
  const classes = useStyles()
  const refValue = useRef(value)

  const [state, setState] = useSetState({
    EventName: value?.EventName || '',
    StartDate: value?.StartDate || Date.now(),
    EndDate: value?.EndDate || Date.now(),
    EventLocation: value?.EventLocation || '',
    AttendanceCap: value?.AttendanceCap || '',
    FeedbackEnable: typeof value?.FeedbackEnable === 'undefined' ? true : value?.FeedbackEnable,
    CookieLess: !!value?.CookieLess,
    Details: value?.Details || '',
    CoverImage: value?.CoverImage || '',
    EnableAttendanceCap: value?.EnableAttendanceCap,
    Fields: (value?.Fields || []) as FieldItem[],
  })
  const {
    Fields,
    EventName,
    StartDate,
    EndDate,
    EventLocation,
    Details,
    EnableAttendanceCap,
    AttendanceCap,
    FeedbackEnable,
    CoverImage,
    CookieLess,
  } = state
  const onInput = (value: string, name: string) => {
    if (name === 'AttendanceCap') {
      const a = Number(value)
      if (value !== '' && a <= 0) return
    }
    // @ts-ignore
    setState({ [name]: value })
  }
  const onChangeFeedbackEnable = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setState({ FeedbackEnable: checked })
  }
  const changeEnableAttendanceCap = useCallback((data) => {
    const isYes = data.target.value === 'true'
    const newState = { EnableAttendanceCap: isYes }
    if (!isYes) {
      Object.assign(newState, { AttendanceCap: 0 })
    }
    setState(newState)
  }, [])
  const onChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const name = event.target.name
    //@ts-ignore
    setState({ [name]: checked })
  }
  const onChangeField = useCallback((fields: FieldItem[]) => {
    setState({ Fields: fields || [] })
  }, [])
  const onSubmit = (value: string) => {
    setState({ Details: value })
  }
  const onSuccess = (value: DragUploadSuccessFile[]) => {
    setState({ CoverImage: value[0]?.fileUrl })
  }
  const onDelete = () => {
    setState({ CoverImage: '' })
  }
  useEffect(() => {
    if (onChange) {
      const data = {}
      const newData = state
      Object.assign(data, refValue.current, newData, { CanSave: isCanSavaQr(QRCodeFunctionType.CheckIn, newData) })
      onChange(data)
    }
  }, [onChange, state])

  useEffect(() => {
    refValue.current = value
  }, [value])
  return (
    <div className={classes.container}>
      <CustomTextField
        required
        label="Event Name"
        disabled={disabledEdit}
        value={EventName}
        name="EventName"
        onInputChange={onInput}
        variant="outlined"
      />
      <CustomTextField
        label="Location"
        required
        value={EventLocation}
        disabled={disabledEdit}
        name="EventLocation"
        onInputChange={onInput}
        variant="outlined"
      />
      <MobileDatePicker
        className={classes.date}
        label="Date"
        disabled={disabledEdit}
        format="MM/dd/yyyy"
        value={StartDate}
        onChange={(a, b) => {
          const dateF = dateFns.date(StartDate || Date.now())
          const dateEnd = dateFns.date(EndDate || Date.now())
          if (dateFns.isValid(a)) {
            const currentValue = dateFns.date(a)
            dateF.setFullYear(currentValue.getFullYear())
            dateF.setMonth(currentValue.getMonth())
            dateF.setDate(currentValue.getDate())
            dateEnd.setFullYear(currentValue.getFullYear())
            dateEnd.setMonth(currentValue.getMonth())
            dateEnd.setDate(currentValue.getDate())
            setState({ StartDate: dateF.getTime(), EndDate: dateEnd.getTime() })
          }
        }}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <Event />
                </InputAdornment>
              ),
            },
          },
        }}
      />
      <MobileTimePicker
        className={classes.date}
        label="Start Time"
        value={StartDate}
        disabled={disabledEdit}
        onChange={(a, b) => {
          const dateF = dateFns.date(StartDate || Date.now())
          if (dateFns.isValid(a)) {
            const currentValue = dateFns.date(a)
            dateF.setHours(currentValue.getHours())
            dateF.setMinutes(currentValue.getMinutes())
            if (EndDate) {
              const endTime = dateFns.date(EndDate)
              if (dateFns.isBefore(endTime, dateF)) {
                SnackBar({ msg: 'Start time must set before end time' })
                return
              }
            }
            setState({ StartDate: dateF.getTime() })
          }
        }}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <AccessTime />
                </InputAdornment>
              ),
            },
          },
        }}
      />
      <MobileTimePicker
        className={classes.date}
        label="End Time"
        value={EndDate}
        disabled={disabledEdit}
        onChange={(a, b) => {
          const dateF = dateFns.date(StartDate || Date.now())
          if (dateFns.isValid(a)) {
            const currentValue = dateFns.date(a)
            dateF.setHours(currentValue.getHours())
            dateF.setMinutes(currentValue.getMinutes())
            const startTime = dateFns.date(StartDate)
            if (dateFns.isBefore(dateF, startTime)) {
              SnackBar({ msg: 'End time should be after start time' })
              return
            }
            setState({ EndDate: dateF.getTime() })
          }
        }}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <AccessTime />
                </InputAdornment>
              ),
            },
          },
        }}
      />
      <RichTextEdit htmlContent={Details} onSubmit={onSubmit} disabledEdit={disabledEdit} />
      <DragUploadFile
        onSuccess={onSuccess}
        onDelete={onDelete}
        type="picture"
        uploadSuffix="Cover image"
        defaultUrl={CoverImage}
      />
      <DragFormPanel
        language={language}
        data={Fields}
        onChangeField={onChangeField}
        onlyMoveArr={onlyMoveArr}
        disabled={disabledEdit}
      />
      <div className={classes.singleCheckBoxRow}>
        <Checkbox
          disabled={disabledEdit}
          onChange={onChangeFeedbackEnable}
          style={{ marginRight: 8, color: '#386BBF' }}
          checked={FeedbackEnable}
        />
        <Typography className={classes.checkBox}>
          I would like to receive text and/or email for event feedback 3 hours after end time related to this event
        </Typography>
      </div>
      <Typography className={classes.attendance}>Attendance Cap?</Typography>
      <RadioGroup
        className={classnames(classes.singleCheckBoxRow, classes.noBorder)}
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={EnableAttendanceCap + ''}
        onChange={changeEnableAttendanceCap}
      >
        <FormControlLabel
          value="true"
          disabled={disabledEdit}
          control={<Radio icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBoxSharp />} />}
          label="Yes"
          className={classes.checkBox}
        />
        <FormControlLabel
          value="false"
          disabled={disabledEdit}
          control={<Radio icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBoxSharp />} />}
          label="No"
          className={classes.checkBox}
        />
      </RadioGroup>
      {EnableAttendanceCap && (
        <CustomTextField
          label="No. of Attendees"
          required
          value={AttendanceCap}
          type="number"
          disabled={disabledEdit}
          name="AttendanceCap"
          onInputChange={onInput}
          variant="outlined"
        />
      )}
      <Typography className={classes.attendance}>Add to Calendar</Typography>
      <RadioGroup
        className={classnames(classes.singleCheckBoxRow, classes.noBorder)}
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value="true"
      >
        <FormControlLabel
          value="true"
          disabled={disabledEdit}
          control={
            <Radio
              icon={<CheckBoxOutlineBlank />}
              checkedIcon={<CheckBoxSharp htmlColor="rgba(25, 118, 210, 0.4)" />}
            />
          }
          label="Yes"
          className={classes.checkBox}
        />
        <FormControlLabel
          value="false"
          disabled={disabledEdit}
          control={<Radio icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBoxSharp />} />}
          label="No"
          className={classes.checkBox}
        />
      </RadioGroup>
      <FormControlLabel
        className={classes.switch}
        control={<Switch checked={CookieLess} onChange={onChangeSwitch} name="CookieLess" />}
        label="No Cookie"
        labelPlacement="start"
      />
    </div>
  )
}

export default WithDefaultData(EventPanel)
