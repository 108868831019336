import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { editProperty, getCustomerSurvey, saveCustomerSurvey, submitAdditionalAns } from '@api'
import { makeStyles } from '@mui/styles'
import CustomerAccordion from '@component/Accordion/CustomerAccordion'
import PropertyInformation, { PROPERTY_I18NAME } from '@/account/PropertyInformation'
import PropertyBranding, { BRANDING_BASE_NAME } from '@/account/PropertyBranding'
import useSetState from '@hooks/useSetState'
import classnames from 'classnames'
import SnackBar from '@component/SnackBar'
import FloorsUnits from '@pages/survey/components/FloorsUnits'
import {
  ConnectivityITF,
  ContentSurveyITF,
  FloorsUnitsITF,
  PhysicalSpacesITF,
  ProgressITF,
  ArchorTenantITF,
} from '@/types/entity'
import Connectivity from '@pages/survey/components/Connectivity'
import PhysicalSpaces from '@pages/survey/components/PhysicalSpaces'
import uuid from 'react-uuid'
import ContactSurvey from '@pages/survey/components/ContactSuvery'
import { Loading } from 'ra-ui-materialui'
import BackButton from '@component/BackButton/BackButton'
import Question from '@pages/survey/components/Question'
import { CheckCircle } from '@mui/icons-material'
import ProductQuestionItem from '@pages/survey/components/ProductQuestionItem'

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    minWidth: 1000,
    padding: '16px 24px',
    backgroundColor: '#F7F7F7',
    textTransform: 'none',
  },
  largeMargin: {
    padding: '16px 80px',
  },
  title: {
    marginTop: 30,
    padding: 12,
    fontSize: 24,
    fontWeight: 600,
    backgroundColor: 'white',
    borderBottom: '1px solid #BCBCBC',
  },
  propertyInfo: {
    marginLeft: -12,
    marginRight: -12,
  },
  progress: {
    marginTop: 32,
    marginBottom: 24,
  },
  submit: {
    fontSize: 14,
    alignSelf: 'center',
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  complete: {
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 24,
    textAlign: 'center',
    marginTop: 24,
  },
  titleClass: {
    fontSize: 24,
    marginBottom: 12,
    fontWeight: 600,
    color: '#211F1F',
  },
  back: {
    marginBottom: 24,
  },
  noPublish: {
    margin: '100px 0',
    textAlign: 'center',
    fontSize: 32,
  },
}))

interface SurveyProgressITF {
  property?: ProgressITF
  branding?: ProgressITF
  floorsUnits?: ProgressITF
  physicalSpaces?: ProgressITF
  connectivity?: ProgressITF
  contacts?: ProgressITF
  managementInfoProgress?: ProgressITF
}

const calculatePercent = (progress: SurveyProgressITF) => {
  let numberator = 0
  let denominator = 0
  Object.keys(progress).forEach((key) => {
    const item = progress[key]
    numberator += item?.Numerator || 0
    denominator += item?.Denominator || 0
  })
  return numberator / denominator
}

const ManagementInfoQuestion = [
  {
    name: 'Workflow',
    type: 'multipleChoice',
    question: 'Which workflow software does your building use?',
    options: [
      { label: 'MRI (Angus)', value: 'MRI (Angus)' },
      { label: 'Building Engines', value: 'Building Engines' },
      { label: 'Impaq', value: 'Impaq' },
      { label: 'Yardi', value: 'Yardi' },
      { label: 'Cove', value: 'Cove' },
      { label: 'Other', value: 'Other' },
      { label: 'None', value: 'None' },
    ],
  },
  {
    name: 'HaveApp',
    type: 'Radio',
    question: 'Does your building have a mobile app?',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
  },
  {
    name: 'AppPlatform',
    type: 'Radio',
    question: 'If applicable, select your mobile app platform.',
    options: [
      { label: 'RISE', value: 'RISE' },
      { label: 'HQO', value: 'HQO' },
      { label: 'Host', value: 'Host' },
      { label: 'Cove', value: 'Cove' },
      { label: 'Customer', value: 'Customer' },
      { label: 'Other', value: 'Other' },
    ],
    dependOnKey: 'HaveApp',
    dependOnValue: 'Yes',
  },
  {
    name: 'Additional',
    type: 'Multiline',
    label: 'Additional Details',
  },
]

interface ContentSurveyWrapProps {
  gId: string
  bId: string
  hiddenMenu?: boolean
  disabledBack?: boolean
  isBuilder?: boolean
  title?: string
  className?: string
  data?: ContentSurveyITF
}

const ContentSurveyWrap: React.FC<ContentSurveyWrapProps> = ({
  gId,
  bId,
  hiddenMenu,
  disabledBack,
  title,
  className,
  data,
  isBuilder,
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const [info, setInfo] = useState<ContentSurveyITF | undefined>(data)
  const {
    EnableBasicPropertyInfo,
    EnablePortfolioAccount,
    EnableBranding,
    EnableContacts,
    EnableManagementInfo,
    EnableConnectivities,
    EnableFloorsUnits,
    EnablePhysicalSpaces,
    ProductQuestions,
    BuilderStatus,
  } = info || {}
  const [progress, setProgress] = useSetState<SurveyProgressITF>({})
  const [showSuccess, setShowSuccess] = useState(false)
  const informationRef = useRef({
    informationData: [],
    getData: () => {},
  })
  const { configs, configsLen } = useMemo(() => {
    return { configs, configsLen }
  }, [])
  const getData = useCallback(async () => {
    const result = await getCustomerSurvey<ContentSurveyITF>({ gId, bId })
    if ((result.PhysicalSpaces || []).length === 0) {
      const PhysicalSpaces = result.PhysicalSpaces.map((item) => ({
        ...item,
        FrontItemKey: uuid(),
      }))
      result.PhysicalSpaces = PhysicalSpaces || []
    }
    if ((result?.TenantInfo?.ArchorTenants || []).length === 0) {
      result.TenantInfo = { ArchorTenants: [{}] as unknown as ArchorTenantITF[] }
    }
    if ((result?.Connectivities || []).length === 0) {
      result.Connectivities = [{}] as unknown as ConnectivityITF[]
    }
    const managementInfo = {
      Workflow: [],
      HaveApp: '',
      AppPlatform: '',
      Additional: '',
    }
    const managementInfoProgress = {
      Numerator: 0,
      Denominator: 2,
    }
    result.ManagementInfo = { ...managementInfo, ...result?.ManagementInfo }
    result.ManagementInfoProgress = { ...managementInfoProgress, ...result?.ManagementInfoProgress }
    setProgress({
      property: result.InfoProgress,
      branding: result.BrandProgress,
      physicalSpaces: result.PhysicalSpacesProgress,
      connectivity: result.ConnectivityProgress,
      contacts: result.ContactProgress,
      floorsUnits: result.FloorsUnitsProgress,
      managementInfoProgress: result.ManagementInfoProgress,
    })
    setInfo(result)
  }, [bId, gId])

  const onSave = useCallback(
    async (index?: number) => {
      const { informationData = [], getData } = informationRef.current || {}
      if (isBuilder) return
      let payloadData = []
      const payload: Record<string, any> = {}

      if (index === 1) {
        payloadData = informationData

        payloadData.forEach((v: any) => {
          const { name, value } = v
          payload[name] = value
          if (PROPERTY_I18NAME.includes(name)) {
            payload[name] = { en: value, es: value }
          }
        })
        payload.InfoProgress = progress.property
      }
      if (index === 2) {
        // @ts-ignore
        const brandingData = (await getData?.()) || {}
        payload.BrandProgress = progress.branding
        Object.assign(payload, brandingData)
      }
      editProperty({ GroupId: gId, BuildingId: bId, EntityId: bId, ...payload })
        .then((res: any) => {
          SnackBar({ msg: 'Successfully saved!', type: 'success' })
        })
        .catch((err) => {
          SnackBar({ msg: err, type: 'error', duration: 3000 })
        })
    },
    [bId, gId, progress, isBuilder]
  )
  const onChangeBranding = useCallback(async () => {
    if (!informationRef.current || isBuilder) return
    const { getData } = informationRef.current
    // @ts-ignore
    const brandingData = (await getData?.()) || {}
    let Numerator = 0
    let Denominator = 0
    Object.keys(BRANDING_BASE_NAME).forEach((key) => {
      Denominator++
      if (brandingData[key]) {
        Numerator++
      }
    })
    setProgress({ branding: { Numerator, Denominator: Denominator } })
  }, [setProgress, isBuilder])
  const onChangeFloorsUnits = useCallback(
    (data: FloorsUnitsITF | undefined, progress: ProgressITF) => {
      if (isBuilder) return
      setProgress({ floorsUnits: progress })
    },
    [setProgress, isBuilder]
  )
  const onChangePhysicalSpaces = useCallback(
    (data: PhysicalSpacesITF[], progress: ProgressITF) => {
      if (isBuilder) return
      setProgress({ physicalSpaces: progress })
    },
    [setProgress, isBuilder]
  )
  const onChangeConnectivity = useCallback(
    (data: ConnectivityITF[], progress: ProgressITF) => {
      if (isBuilder) return
      setProgress({ connectivity: progress })
    },
    [setProgress, isBuilder]
  )
  const onChangeContact = useCallback(
    (progress: ProgressITF) => {
      setProgress({ contacts: progress })
    },
    [setProgress]
  )
  const calculateProperty = useCallback((data: any[]) => {
    let Numerator = data.filter((item: any) => item.name !== 'Shifts' && !!item.value).length
    const Denominator = data.filter((item: any) => item.name !== 'Shifts').length
    if (data.find((item: any) => item.name === 'PropertyType')?.value === 'Single') {
      Numerator++
    }
    return { Numerator, Denominator }
  }, [])
  const onChangeProperty = useCallback(() => {
    if (isBuilder) return
    const { informationData = [] } = informationRef.current
    setProgress({ property: calculateProperty(informationData) })
  }, [setProgress, isBuilder])
  const handleManagementInfoChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, item: Record<string, any>) => {
      if (isBuilder) return
      const { name, value } = event.target
      const { dataKey, type, itemName } = item

      setInfo((prevState) => {
        const changeItem = prevState?.[dataKey]
        if (type === 'multipleChoice') {
          if (changeItem[itemName].includes(name)) {
            changeItem[itemName] = changeItem[itemName].filter((v: string) => v !== name)
          } else {
            changeItem[itemName].push(name)
          }
        } else {
          changeItem[name] = value
        }
        const Numerator = [changeItem.Workflow, changeItem.HaveApp].filter((v) => !!v?.length).length
        setProgress({ managementInfoProgress: { Numerator, Denominator: 2 } })
        return Object.assign({}, prevState, { [dataKey]: changeItem })
      })
    },
    [isBuilder]
  )

  const onSaveSurvey = useCallback(
    async (data: any) => {
      if (isBuilder) return
      const params = { GroupId: gId, BuildingId: bId }
      Object.assign(params, data)
      saveCustomerSurvey<ContentSurveyITF>(params)
        .then((res: any) => {
          if (data.FloorsUnits) {
            setInfo((prevState) => {
              return Object.assign({}, prevState, { FloorsFromPattern: res.FloorsFromPattern })
            })
          }
          SnackBar({ msg: 'Successfully saved!', type: 'success' })
        })
        .catch((err) => {
          SnackBar({ msg: err, type: 'error', duration: 3000 })
        })
    },
    [bId, gId, isBuilder]
  )
  const onSaveSurveyVerify = useCallback(
    async (key: string) => {
      if (isBuilder) return
      saveCustomerSurvey({
        GroupId: gId,
        BuildingId: bId,
        [key]: info?.[key],
        ManagementInfoProgress: progress.managementInfoProgress,
      })
        .then((res: any) => {
          SnackBar({ msg: 'Successfully saved!', type: 'success' })
        })
        .catch((err) => {
          SnackBar({ msg: err, type: 'error', duration: 3000 })
        })
    },
    [gId, bId, info, progress, isBuilder]
  )
  const onSubmit = useCallback(() => {
    const { physicalSpaces } = progress
    if (isBuilder) return
    if (!EnablePhysicalSpaces || (EnablePhysicalSpaces && (physicalSpaces?.Numerator || 0) > 0)) {
      setShowSuccess(true)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else {
      SnackBar({ msg: 'You must select at lease one physical space to proceed' })
    }
  }, [EnablePhysicalSpaces, bId, gId, navigate, progress, isBuilder])

  const onSubmitField = useCallback(
    (data: Object[], code: string) => {
      if (isBuilder) return
      submitAdditionalAns({ GroupId: gId, BuildingId: bId, Code: code, FieldsValues: data }).then((res) => {
        SnackBar({ msg: 'Successfully saved!', type: 'success' })
      })
    },
    [bId, gId, navigate, progress, isBuilder]
  )

  useEffect(() => {
    if (data) return
    getData()
  }, [getData])
  useEffect(() => {
    if (!data) return
    setInfo(data)
  }, [data])
  const { list, len } = useMemo(() => {
    const list = [
      {
        label: 'Portfolio Account',
        value: 'EnablePortfolioAccount',
        contentClass: classes.propertyInfo,
        defaultExpanded: !data,
        show: EnablePortfolioAccount,
      },
      {
        label: 'Branding',
        value: 'EnableBranding',
        show: EnableBranding,
      },
      {
        label: 'Contacts',
        value: 'EnableContacts',
        show: EnableContacts,
      },
      {
        label: 'Building Management Info',
        value: 'EnableManagementInfo',
        show: EnableManagementInfo,
      },
      {
        label: 'Connectivity',
        value: 'EnableConnectivities',
        show: EnableConnectivities,
      },
      {
        label: 'Floors & Units',
        value: 'EnableFloorsUnits',
        show: EnableFloorsUnits,
      },
      {
        label: 'Physical Spaces',
        value: 'EnablePhysicalSpaces',
        show: EnablePhysicalSpaces,
      },
    ].filter((item) => !!item.show)
    return { list, len: list.length }
  }, [
    EnableBranding,
    EnableConnectivities,
    EnableContacts,
    EnableFloorsUnits,
    EnableManagementInfo,
    EnablePhysicalSpaces,
    EnablePortfolioAccount,
  ])
  if (!info) return <Loading />
  if (!isBuilder && (!BuilderStatus || BuilderStatus === 'Draft')) {
    return (
      <Box className={classnames(classes.page, { [classes.largeMargin]: hiddenMenu }, className)}>
        <BackButton
          titleClass={classes.titleClass}
          border
          title={title || 'Basic Info Survey'}
          disabledBack={disabledBack}
          className={classes.back}
        />
        <Typography className={classes.noPublish}>
          The survey form for your building is not available at this point. Please contact your customer rep.
        </Typography>
      </Box>
    )
  }
  return (
    <Box className={classnames(classes.page, { [classes.largeMargin]: hiddenMenu }, className)}>
      <BackButton
        titleClass={classes.titleClass}
        border
        title={title || 'Basic Info Survey'}
        disabledBack={disabledBack}
        className={classes.back}
      />
      {!showSuccess && (
        <>
          {list.map((item, index) => {
            let child: React.ReactNode
            if (item.value === 'EnablePortfolioAccount') {
              child = (
                <PropertyInformation
                  disabled={false}
                  validate={false}
                  hiddenCancel
                  informationRef={informationRef}
                  isEdit
                  showSave
                  onChangeData={onChangeProperty}
                  editData={info}
                  onSave={() => onSave(1)}
                  hiddenShifts
                  gId={gId}
                />
              )
            } else if (item.value === 'EnableBranding') {
              child = (
                <PropertyBranding
                  gId={gId}
                  hiddenPreview
                  informationRef={informationRef}
                  isEdit
                  showSave
                  hiddenCancel
                  editData={info}
                  onChangeData={onChangeBranding}
                  onSave={() => onSave(2)}
                />
              )
            } else if (item.value === 'EnableContacts') {
              child = (
                <ContactSurvey
                  contacts={info?.Contacts}
                  tenant={info?.TenantInfo}
                  onSave={onSaveSurvey}
                  onChangeData={onChangeContact}
                  gId={gId}
                  bId={bId}
                />
              )
            } else if (item.value === 'EnableManagementInfo') {
              child = (
                <Question
                  key={'Info'}
                  dataKey={'ManagementInfo'}
                  data={info?.ManagementInfo}
                  question={ManagementInfoQuestion}
                  onSave={() => onSaveSurveyVerify('ManagementInfo')}
                  handleChange={handleManagementInfoChange}
                />
              )
            } else if (item.value === 'EnableConnectivities') {
              child = (
                <Connectivity data={info?.Connectivities} onChangeData={onChangeConnectivity} onSave={onSaveSurvey} />
              )
            } else if (item.value === 'EnableFloorsUnits') {
              child = <FloorsUnits data={info?.FloorsUnits} onChangeData={onChangeFloorsUnits} onSave={onSaveSurvey} />
            } else if (item.value === 'EnablePhysicalSpaces') {
              child = (
                <PhysicalSpaces
                  data={info?.PhysicalSpaces}
                  floorsFromPattern={info?.FloorsFromPattern}
                  otherAmenities={info?.OtherAmenities}
                  onChangeData={onChangePhysicalSpaces}
                  onSave={onSaveSurvey}
                />
              )
            }

            return (
              <CustomerAccordion
                key={item.value}
                title={`${index + 1}. ${item.label || ''}`}
                contentClass={item.contentClass}
                defaultExpanded={item.defaultExpanded}
              >
                {child}
              </CustomerAccordion>
            )
          })}
          {ProductQuestions?.map((item, index) => {
            return (
              <ProductQuestionItem
                key={item.Code}
                onSubmitField={onSubmitField}
                item={item}
                index={index}
                len={len}
                gId={gId}
              />
            )
          })}
          {!isBuilder && (
            <Button variant="contained" classes={{ contained: classes.submit }} onClick={onSubmit}>
              SUBMIT
            </Button>
          )}
        </>
      )}
      <Box className={classes.complete} style={{ display: showSuccess ? '' : 'none' }}>
        <CheckCircle style={{ fontSize: 120, color: '#50B258' }} />
        <Typography sx={{ fontSize: 30, textAlign: 'center' }}>Thank you for submitting your survey!</Typography>
      </Box>
    </Box>
  )
}

export default React.memo(ContentSurveyWrap)
