import React from 'react'
import { makeStyles } from '@mui/styles'
import WithDefaultData from '@/hoc/WithDefaultData'
import { FormControlLabel, Switch, Typography } from '@mui/material'
import { isCanSavaQr } from '@/utils'
import useSetState from '@hooks/useSetState'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  },
  switch: {
    marginTop: 12,
    marginLeft: 0,
    alignSelf: 'flex-start',
  },
  title: {
    color: '#466CB5',
    fontSize: 20,
    fontWeight: 600,
  },
  desc: {
    color: '#211F1F',
  },
}))

const StaffCheckInPanel: React.FC<{ isHub?: boolean; value: any; onChange?: (data: any) => void }> = ({
  isHub,
  value,
  onChange,
}) => {
  const classes = useStyles()
  const [data, setData] = useSetState({
    CookieLess: !!value?.CookieLess,
  })
  const { CookieLess } = data
  const onChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const name = event.target.name
    const newData = Object.assign({}, value, data, { [name]: checked })
    Object.assign(newData, { CanSave: true })
    //@ts-ignore
    setData({ [name]: checked })
    onChange?.(newData)
  }
  return (
    <div className={classes.container}>
      <Typography className={classes.desc}>
        Please refer to the mobile preview for the content of this QR code.
      </Typography>
      <FormControlLabel
        className={classes.switch}
        control={<Switch checked={CookieLess} onChange={onChangeSwitch} name="CookieLess" />}
        label="No Cookie"
        labelPlacement="start"
      />
    </div>
  )
}

export default WithDefaultData(StaffCheckInPanel)
