import React, { useCallback } from 'react'
import { Datagrid, List, TextField, Resource, TopToolbar } from 'react-admin'
import { ListProps, TextFieldProps, useRecordContext, FunctionField } from 'react-admin'
import { Typography, Box, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import useSetState from '@hooks/useSetState'
import { useNavigate } from 'react-router-dom'
import CONFIG from '@constant/config'
import { AccountSearch } from '@/dashboard/AdminSearch'
import { PropertySearch } from '@/dashboard/PropertySearchBar'
import { GroupITF } from '@/types/group'
import { IEntity } from '@/types'
import { copyText } from '@/utils'

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: '#f5f5f5',
      padding: '0 24px 24px',
      position: 'relative',
      fontFamily: 'Roboto',
    },
    header: {
      padding: '0px 0 24px',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 24,
    },
    textField: {
      width: 200,
      backgroundColor: '#f7f7f7',
    },
    toolbar: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 0,
      marginBottom: 24,
    },
    title: {
      fontWeight: 'bold',
      fontSize: 20,
      color: '#211F1F',
    },
    link: {
      width: 100,
      overflow: 'scroll',
      whiteSpace: 'nowrap',
      textDecoration: 'underline',
      color: '#1976D2',
      cursor: 'pointer',
    },
  }
})

const PrintURL = (props: TextFieldProps & { onDelete?: (value: string) => void; isEdit?: boolean }) => {
  const record = useRecordContext(props)
  const navigate = useNavigate()
  const classesInter = useStyles()
  const { isEdit } = props
  // @ts-ignore
  if (!props.source) return null
  let value = `/surveyLibrary/surveyBuilder/${record.GroupId}/${record.BuildingId}`
  if (!isEdit) {
    value = `/contentSurvey/${record.GroupId}/${record.BuildingId}`
  }
  const onClick = () => {
    if (isEdit) {
      navigate(value, { replace: true })
      return
    } else {
      navigate(value)
    }
  }
  return (
    <Typography className={classesInter.link} onClick={onClick}>
      {`${CONFIG.adminHost}${value}`}
    </Typography>
  )
}

const Actions: React.FC = () => {
  const classes = useStyles()
  return (
    <TopToolbar className={classes.toolbar}>
      <Typography className={classes.title}>Survey List</Typography>
    </TopToolbar>
  )
}
const SurveyDataList = (props: ListProps) => {
  const classes = useStyles()
  const [data, setData] = useSetState<{ gId?: string; bId?: string }>({})
  const { gId, bId } = data
  // const { gId= '34cafd30-6bbe-11ed-b9b6-3fdc2c623dbc', bId= '0fc2b5a0-1497-11ee-a087-518f1ccb9862' } = data
  const onChangeAccount = useCallback(
    (account: GroupITF) => {
      setData({ gId: account.acId, bId: '' })
    },
    [setData]
  )

  const onChangeProperty = useCallback(
    (property: IEntity) => {
      setData({ bId: property.id })
    },
    [setData]
  )
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <AccountSearch label="Account" onChange={onChangeAccount} gId={gId || ''} inputRootClass={classes.textField} />
        <PropertySearch
          label="Building"
          gId={gId || ''}
          bId={bId || ''}
          onChange={onChangeProperty}
          inputRootClass={classes.textField}
        />
      </Box>
      <List {...props} filter={{ gId, bId }} empty={false} perPage={50} actions={<Actions />}>
        <Datagrid bulkActionButtons={false} optimized>
          <TextField source="GroupName.en" label="Account" noWrap sortable={false} />
          <TextField source="BuildingName.en" label="Property" noWrap sortable={false} />
          <TextField source="Status" label="Status" noWrap sortable={false} />
          <TextField source="SurveyName" label="Survey Name" noWrap sortable={false} />
          <PrintURL sortable={false} source="CMSUrl" label="Link to edit" isEdit />
          <PrintURL sortable={false} source="CMSUrl" label="Link to view responses" />
          <FunctionField
            sortable={false}
            source="Status"
            label="Customer Link"
            render={(record) => {
              const link = `${CONFIG.adminHost}/surveyWelcome/contentSurvey/${record.BuildingId}`
              if (record.Status !== 'Completed') return ''
              return (
                <Button variant="contained" onClick={() => copyText(link)}>
                  COPY
                </Button>
              )
            }}
          />
        </Datagrid>
      </List>
    </Box>
  )
}

const SurveyList = () => {
  return <Resource name="surveyList" list={SurveyDataList} />
}

export default SurveyList
