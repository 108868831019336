import FeedbackWrapper from '@component/Feedback/FeedbackWrapper'
import SocialFormWrapper from '@component/Marketing/SocialFormWrapper'
import GoogleMapWrapper from '@component/Marketing/GoogleMapWrapper'
import AnnouncementFormWrapper from '@component/Property/AnnouncementFormWrapper'
import { QRCodeFunctionType } from '../types'
import JoinWifiForm from '@component/Form/JoinWifiForm'
import { FieldForm, FileViewer, PdfViewer, URLViewer } from '@activate-inc/activate-ui'
import FieldEventWrapper from '@component/Marketing/FieldEventWrapper'
import RenewalWrapper from '@component/Property/RenewalWrapper'
import ServiceRequestForm from '@component/Mobile/ServiceRequestForm'
import FrontDeskWrapper from '@/components/Operations/FrontDeskWrapper'
import MoveWrapper from '@/components/Operations/MoveWrapper'
import CustomerSupportWrapper from '@/components/Operations/CustomerSupportQR'
import HtmlWrapper from '@component/Marketing/HtmlWrapper'
import PortfolioWrapper from '@component/Marketing/PortfolioWrapper'
import DownloadAppWrapper from '@component/Marketing/DownloadAppWrapper'
import FireAndAEDWrapper from '@component/Marketing/FireAndAEDWrapper'
import StaticWrapper from '@component/Marketing/StaticWrapper'
import 'react-phone-input-2/lib/material.css'

import GoogleMapFormData from '@component/FormData/GoogleMapFormData'
import JoinWifiFormData from '@component/FormData/JoinWifiFormData'
import PortfolioFormData from '@component/FormData/PortfolioFormData'

import StaticPanel from '@component/EditPanel/StaticPanel'
import FeedBackPanel from '@component/EditPanel/FeedBackPanel'
import SelectTemplatePanel from '@/components/EditPanel/SelectTemplatePanel'
import SocialFormPanel from '@component/EditPanel/SocialFormPanel'
import FrontDeskPanel from '@component/EditPanel/FrontDeskPanel'
import HtmlFormPanel from '@component/EditPanel/EditHtml'
import HubWrapper from '@component/Hub/HubWrapper'
import FieldFormPanel from '@component/EditPanel/FieldFormPanel'
import SelectTemplate from '@component/Entity/SelectTemplate'
import PhoneCallPanel from '@component/EditPanel/PhoneCallPanel'
import DownloadAppPanel from '@component/EditPanel/DownloadAppPanel'
import FireExtinguisherPanel from '@component/EditPanel/FireExtinguisherPanel'
import BathroomServicePanel from '@component/EditPanel/BathroomServicePanel'
import LeadCapturePanel from '@component/EditPanel/LeadCapture'
import EventPanel from '@component/EditPanel/EventPanel'
import EventWrapper from '@component/Marketing/EventWrapper'
import FieldMarketingPanel from '@component/EditPanel/FieldMarketing'
import RoomRental from '@component/EditPanel/RoomRental'
import SupplierCheckin, { SupplierCheckinNoDefault } from '@component/EditPanel/SupplierCheckin'
import EquipmentPanel from '@component/EditPanel/EquipmentPanel'
import CustomerFeedback from '@component/EditPanel/CustomerFeedback'
import WrapCustomerFeedback from '@component/Feedback/WrapCustomerFeedback'
import AngusServiceRequestPanel from '@component/EditPanel/AngusServiceRequestPanel'
import InspectionPanel from '@component/EditPanel/InspectionPanel'
import RoundInspectionPanel from '@component/EditPanel/RoundInspectionPanel'
import CommentCardPanel from '@component/EditPanel/CommentCard'
import CheckinWrapper from '@component/Marketing/CheckinWrapper'
import InventoryPanel from '@component/EditPanel/InventoryPanel'
import InventoryForm from '@component/Mobile/InventoryForm'
import ActivityScheduleForm from '@component/Mobile/ActivityScheduleForm'
import ActivitySchedulePanel from '@component/EditPanel/ActivitySchedulePanel'
import RenewalPanel from '@component/EditPanel/RenewalPanel'
import NewSupplierForm from '@component/Mobile/NewSupplierForm'
import EmptyPanel from '@component/EditPanel/EmptyPanel'
import LadderSafetyPanel from '@component/EditPanel/LadderSafetyPanel'
import LadderForm from '@component/Mobile/LadderForm'
import MovePanel from '@component/EditPanel/MovePanel'
import PermitForm from '@component/Mobile/PermitForm'
import MoveOutPanel from '@component/EditPanel/MoveOutPanel'
import MoveOutWrapper from '@component/Mobile/MoveOutWrapper'
import BurnPermitPanel from '@component/EditPanel/BurnPermitPanel'
import BurnPermitForm from '@component/Mobile/BurnPermitForm'
import MaintenanceRequestPanel from '@component/EditPanel/MaintenanceRequestPanel'
import ServiceRequestPanel from '@component/EditPanel/ServiceRequestPanel'
import ResidentCheckOutPanel from '@component/EditPanel/ResidentCheckOutPanel'
import StaffCheckInPanel from '@component/EditPanel/StaffCheckInPanel'
import StaffCheckInForm from '@component/Marketing/StaffCheckInForm'
import RoofAccessCarousel from '@component/EditPanel/RoofAccessCarousel'

//Mobile Frame Display
const DynamicComponentsMaps = {
  [QRCodeFunctionType.Static]: StaticWrapper,
  [QRCodeFunctionType.EmbeddedStaticUrl]: URLViewer,
  [QRCodeFunctionType.Video]: URLViewer,
  [QRCodeFunctionType.SocialMedia]: SocialFormWrapper,
  [QRCodeFunctionType.Feedback]: FeedbackWrapper,
  [QRCodeFunctionType.JoinWifi]: JoinWifiForm,
  [QRCodeFunctionType.PDF]: PdfViewer,
  [QRCodeFunctionType.EmbeddedFile]: FileViewer,
  [QRCodeFunctionType.Announcements]: AnnouncementFormWrapper,
  [QRCodeFunctionType.Purchase]: FieldForm,
  [QRCodeFunctionType.Communication]: FieldForm,
  [QRCodeFunctionType.SupplierCheckin]: CheckinWrapper,
  [QRCodeFunctionType.RoofAccess]: CheckinWrapper,
  [QRCodeFunctionType.RoofAccessCarousel]: CheckinWrapper,
  [QRCodeFunctionType.TenantHVAC]: CheckinWrapper,
  [QRCodeFunctionType.StudentCheckin]: CheckinWrapper,
  [QRCodeFunctionType.GuestCheckin]: CheckinWrapper,
  [QRCodeFunctionType.ResidentCheckIn]: CheckinWrapper,
  [QRCodeFunctionType.FieldEvent]: FieldEventWrapper,
  [QRCodeFunctionType.Vacancy]: FieldForm,
  [QRCodeFunctionType.GoogleMap]: GoogleMapWrapper,
  [QRCodeFunctionType.Renewal]: RenewalWrapper,
  [QRCodeFunctionType.Maintenance]: ServiceRequestForm,
  [QRCodeFunctionType.AngusServiceRequest]: ServiceRequestForm,
  [QRCodeFunctionType.QrHub]: HubWrapper,
  [QRCodeFunctionType.FrontDesk]: FrontDeskWrapper,
  [QRCodeFunctionType.Html]: HtmlWrapper,
  [QRCodeFunctionType.SimpleForm]: FieldForm,
  [QRCodeFunctionType.Portfolio]: PortfolioWrapper,
  [QRCodeFunctionType.MoveOut]: MoveOutWrapper,
  [QRCodeFunctionType.MoveOutResident]: MoveOutWrapper,
  [QRCodeFunctionType.MoveIn]: MoveWrapper,
  [QRCodeFunctionType.Support]: CustomerSupportWrapper,
  [QRCodeFunctionType.DownloadApp]: DownloadAppWrapper,
  [QRCodeFunctionType.FireExtinguisher]: FireAndAEDWrapper,
  [QRCodeFunctionType.AEDInspection]: FireAndAEDWrapper,
  [QRCodeFunctionType.BathroomService]: FieldForm,
  [QRCodeFunctionType.LeadCapture]: EventWrapper,
  [QRCodeFunctionType.EVRequest]: EventWrapper,
  [QRCodeFunctionType.FieldMarketing]: EventWrapper,
  [QRCodeFunctionType.RoomRental]: EventWrapper,
  [QRCodeFunctionType.CheckIn]: EventWrapper,
  [QRCodeFunctionType.CustomerFeedback]: WrapCustomerFeedback,
  [QRCodeFunctionType.CommentCard]: FieldForm,
  [QRCodeFunctionType.Inventory]: InventoryForm,
  [QRCodeFunctionType.ActivitySchedule]: ActivityScheduleForm,
  [QRCodeFunctionType.NewSupplier]: NewSupplierForm,
  [QRCodeFunctionType.EventRegistration]: EventWrapper,
  [QRCodeFunctionType.LadderSafety]: LadderForm,
  [QRCodeFunctionType.Permit]: PermitForm,
  [QRCodeFunctionType.BEServiceRequest]: ServiceRequestForm,
  [QRCodeFunctionType.BurnPermit]: BurnPermitForm,
  [QRCodeFunctionType.MaintenanceRequest]: ServiceRequestForm,
  [QRCodeFunctionType.ResidentCheckOut]: FieldForm,
  [QRCodeFunctionType.StaffCheckIn]: StaffCheckInForm,
  [QRCodeFunctionType.TourCheckin]: CheckinWrapper,
}

const DynamicEditePanelComponentsMaps = {
  [QRCodeFunctionType.PDF]: StaticPanel,
  [QRCodeFunctionType.EmbeddedFile]: StaticPanel,
  [QRCodeFunctionType.Static]: StaticPanel,
  [QRCodeFunctionType.EmbeddedStaticUrl]: StaticPanel,
  [QRCodeFunctionType.Video]: StaticPanel,
  [QRCodeFunctionType.SocialMedia]: SocialFormPanel,
  [QRCodeFunctionType.QrHub]: SelectTemplate,
  [QRCodeFunctionType.FrontDesk]: FrontDeskPanel,
  [QRCodeFunctionType.Feedback]: FeedBackPanel,
  [QRCodeFunctionType.CustomerFeedback]: CustomerFeedback,
  [QRCodeFunctionType.JoinWifi]: JoinWifiFormData,
  [QRCodeFunctionType.Purchase]: FieldFormPanel,
  [QRCodeFunctionType.Communication]: FieldFormPanel,
  [QRCodeFunctionType.FieldEvent]: FieldFormPanel,
  [QRCodeFunctionType.Vacancy]: FieldFormPanel,
  [QRCodeFunctionType.Renewal]: RenewalPanel,
  [QRCodeFunctionType.Maintenance]: ServiceRequestPanel,
  [QRCodeFunctionType.GoogleMap]: GoogleMapFormData,
  [QRCodeFunctionType.Html]: HtmlFormPanel,
  [QRCodeFunctionType.SimpleForm]: FieldFormPanel,
  [QRCodeFunctionType.MoveOut]: MoveOutPanel,
  [QRCodeFunctionType.MoveIn]: MovePanel,
  [QRCodeFunctionType.Support]: FieldFormPanel,
  [QRCodeFunctionType.RoofAccess]: SupplierCheckinNoDefault,
  [QRCodeFunctionType.RoofAccessCarousel]: RoofAccessCarousel,
  [QRCodeFunctionType.TenantHVAC]: SupplierCheckinNoDefault,
  [QRCodeFunctionType.SupplierCheckin]: SupplierCheckinNoDefault,
  [QRCodeFunctionType.StudentCheckin]: SupplierCheckin,
  [QRCodeFunctionType.GuestCheckin]: SupplierCheckin,
  [QRCodeFunctionType.ResidentCheckIn]: SupplierCheckin,
  [QRCodeFunctionType.TourCheckin]: SupplierCheckin,
  [QRCodeFunctionType.CheckIn]: EventPanel,
  [QRCodeFunctionType.StaffCheckIn]: StaffCheckInPanel,
  [QRCodeFunctionType.Portfolio]: PortfolioFormData,
  [QRCodeFunctionType.PhoneCall]: PhoneCallPanel,
  [QRCodeFunctionType.DownloadApp]: DownloadAppPanel,
  [QRCodeFunctionType.FireExtinguisher]: FireExtinguisherPanel,
  [QRCodeFunctionType.AEDInspection]: FireExtinguisherPanel,
  [QRCodeFunctionType.BathroomService]: BathroomServicePanel,
  [QRCodeFunctionType.LeadCapture]: LeadCapturePanel,
  [QRCodeFunctionType.EVRequest]: LeadCapturePanel,
  [QRCodeFunctionType.FieldMarketing]: FieldMarketingPanel,
  [QRCodeFunctionType.EventRegistration]: FieldMarketingPanel,
  [QRCodeFunctionType.RoomRental]: RoomRental,
  [QRCodeFunctionType.EquipmentInspection]: EquipmentPanel,
  [QRCodeFunctionType.AngusServiceRequest]: AngusServiceRequestPanel,
  [QRCodeFunctionType.Inspection]: InspectionPanel,
  [QRCodeFunctionType.RoundInspection]: RoundInspectionPanel,
  [QRCodeFunctionType.CommentCard]: CommentCardPanel,
  [QRCodeFunctionType.Inventory]: InventoryPanel,
  [QRCodeFunctionType.ActivitySchedule]: ActivitySchedulePanel,
  [QRCodeFunctionType.NewSupplier]: EmptyPanel,
  [QRCodeFunctionType.TenantHealth]: EmptyPanel,
  [QRCodeFunctionType.RoundReport]: EmptyPanel,
  [QRCodeFunctionType.SupplierCheckReport]: EmptyPanel,
  [QRCodeFunctionType.FeedbackLocationReport]: EmptyPanel,
  [QRCodeFunctionType.TenantPerformance]: EmptyPanel,
  [QRCodeFunctionType.Permit]: EmptyPanel,
  [QRCodeFunctionType.LadderSafety]: LadderSafetyPanel,
  [QRCodeFunctionType.BEServiceRequest]: AngusServiceRequestPanel,
  [QRCodeFunctionType.BurnPermit]: BurnPermitPanel,
  [QRCodeFunctionType.MoveOutResident]: MoveOutPanel,
  [QRCodeFunctionType.MaintenanceRequest]: MaintenanceRequestPanel,
  [QRCodeFunctionType.EquipmentLookup]: EmptyPanel,
  [QRCodeFunctionType.CleaningRoundReport]: EmptyPanel,
  [QRCodeFunctionType.SecurityPatrolDashboard]: EmptyPanel,
  [QRCodeFunctionType.SupplierList]: EmptyPanel,
  [QRCodeFunctionType.ResidentCheckOut]: ResidentCheckOutPanel,
  [QRCodeFunctionType.BathroomServiceReport]: EmptyPanel,
  [QRCodeFunctionType.PortfolioInspectionDashboard]: EmptyPanel,
  [QRCodeFunctionType.BuildingInspectionDashboard]: EmptyPanel,
}

export const isSaveTemplateId = (type?: QRCodeFunctionType) => {
  if (!type) return false
  return [
    QRCodeFunctionType.QrHub,
    QRCodeFunctionType.Purchase,
    QRCodeFunctionType.Communication,
    QRCodeFunctionType.FieldMarketing,
    QRCodeFunctionType.FieldEvent,
    QRCodeFunctionType.Renewal,
    QRCodeFunctionType.Maintenance,
    QRCodeFunctionType.SimpleForm,
    QRCodeFunctionType.MoveOut,
    QRCodeFunctionType.MoveIn,
    QRCodeFunctionType.Support,
    QRCodeFunctionType.SupplierCheckin,
    QRCodeFunctionType.AEDInspection,
    QRCodeFunctionType.FireExtinguisher,
    QRCodeFunctionType.Vacancy,
    QRCodeFunctionType.EventRegistration,
    QRCodeFunctionType.AngusServiceRequest,
    QRCodeFunctionType.BEServiceRequest,
    QRCodeFunctionType.MoveOutResident,
  ].includes(type)
}

const DisabledBuildingQr = [QRCodeFunctionType.AngusServiceRequest, QRCodeFunctionType.BEServiceRequest]

export { DynamicComponentsMaps, DynamicEditePanelComponentsMaps, DisabledBuildingQr }
