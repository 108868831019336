import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import useSetState from '@hooks/useSetState'
import { AddCircle, ExpandMore } from '@mui/icons-material'
import PanelSelection from '@component/EditPanel/PanelSelection'
import {
  Resource,
  DateField,
  TextField,
  List,
  TextFieldProps,
  useListContext,
  useRecordContext,
  TopToolbar,
  useRefresh,
} from 'react-admin'
import classnames from 'classnames'
import get from 'lodash/get'
import { PanelChoice } from '@/types/panel'
import { updatePrintProofNote, updateCarouselNote } from '@api'
import DatagridCustomize from '@component/List/DatagridCustomize'
import AddNoteModal from '@component/Modal/AddNoteModal'
import { REQUEST_TYPE_CHOICE } from '@constant/app'

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: '#F7F7F7',
    marginTop: '-98px',
  },
  pdf: {
    minHeight: '100vh',
    marginTop: 0,
    padding: 24,
  },
  header: {
    display: 'flex',
    padding: '18px 0',
    height: 100,
    justifyContent: 'flex-end',
  },
  paper: {
    minWidth: 1400,
    backgroundColor: '#F5F5F5',
    flex: 1,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    padding: '18px 0px 18px',
    marginTop: -98,
  },
  list: {},
  select: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    backgroundColor: '#FFF',
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    borderRadius: 8,
  },
  empty: {
    display: 'flex',
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF',
    marginTop: 24,
  },
  toolbar: {
    width: '100%',
    display: 'block',
    paddingTop: 12,
    minHeight: 'auto',
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 'auto',
    fontFamily: 'Roboto, Regular',
    fontSize: 14,
    color: '#466CB5',
    cursor: 'pointer',
    marginRight: 24,
  },
}))

const PROOF = ['Content Proof', 'Sign Proof'].map((item) => ({
  label: item,
  value: item.replaceAll(' ', ''),
}))
type Column = TextFieldProps | Record<string, any>
const column: Column[] = [
  {
    source: 'CreatedDate',
    label: 'Date',
    sortable: true,
    locales: 'en-US',
    options: { year: 'numeric', month: '2-digit', day: '2-digit' },
  },
  { source: 'QrName', label: 'Hub', noWrap: true, sortable: true },
  { source: 'ButtonName', label: 'Page', sortable: true },
  { source: 'Category', label: 'Category', sortable: true },
  { source: 'Type', label: 'Type', sortable: true },
  { source: 'RequestType', label: 'Request Type', sortable: true },
  { source: 'Note', label: 'Note', sortable: true, sx: { minWidth: 300, display: 'inline-block' } },
]
const signColumn: Column[] = [
  {
    source: 'CreatedDate',
    label: 'Date',
    sortable: true,
    locales: 'en-US',
    options: { year: 'numeric', month: '2-digit', day: '2-digit' },
  },
  { source: 'QrName', label: 'Qr Name', sortable: true },
  { source: 'Category', label: 'Category', sortable: true },
  { source: 'Type', label: 'Type', sortable: true },
  { source: 'RequestType', label: 'Request Type', sortable: true },
  { source: 'Note', label: 'Note', sortable: true },
]

const RequestTypeFiled = (props: TextFieldProps) => {
  const { resource } = useListContext()
  const record = useRecordContext(props)
  const value = get(record, props?.source || '')
  const [data, setData] = useState(value)
  useEffect(() => {
    setData(value)
  }, [value])
  const onChange = useCallback((value) => {
    setData(value.value)
    record.RequestType = value.value
    const { api, params } =
      resource === 'masterNotepadContent'
        ? { api: updateCarouselNote, params: { CarouselId: record.CarouselId } }
        : { api: updatePrintProofNote, params: { ProofId: record.ProofId } }
    api(Object.assign(params, { RequestType: value.value, CreatedDate: record.CreatedDate }))
  }, [])
  return (
    <PanelSelection
      height={40}
      value={REQUEST_TYPE_CHOICE?.find((item1) => item1.value === data)}
      choices={REQUEST_TYPE_CHOICE}
      onChange={onChange}
      popupIcon={<ExpandMore />}
      width={'220px'}
    />
  )
}
const PropertyListContent = () => {
  const { resource } = useListContext()
  const columns = resource === 'masterNotepadContent' ? column : signColumn
  return (
    <DatagridCustomize bulkActionButtons={false}>
      {columns.map((record, index) => {
        const { source, sortable = false, textAlign = 'left' } = record
        if (source === 'CreatedDate') {
          //@ts-ignore
          return <DateField key={index} {...record} sortable={sortable} textAlign={textAlign} emptyText="N/A" />
        }
        if (source === 'RequestType') {
          //@ts-ignore
          return <RequestTypeFiled key={index} {...record} sortable={sortable} emptyText="N/A" />
        }
        return <TextField key={index} {...record} sortable={sortable} textAlign={textAlign} emptyText="N/A" />
      })}
    </DatagridCustomize>
  )
}
interface MasterNotepadProps {
  gId?: string
  bId?: string
  isPdf?: boolean
  type: string
}
const ListAction: React.FC<{ gId: string; bId: string; type: string }> = ({ gId, bId, type }) => {
  const classes = useStyles()
  const [show, setShow] = useState(false)
  const refresh = useRefresh()
  const onClose = useCallback((success: boolean) => {
    setShow(false)
    success && refresh()
  }, [])
  return (
    <TopToolbar className={classes.toolbar}>
      {show && <AddNoteModal gId={gId} bId={bId} onClose={onClose} type={type} />}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}>
        <Typography>Master Notepad</Typography>
        <Box className={classes.addButton} onClick={() => setShow(true)}>
          <AddCircle color="primary" style={{ fontSize: 24, marginRight: 8 }} />
          Add new note
        </Box>
      </Box>
    </TopToolbar>
  )
}
const MasterNotepad: React.FC<MasterNotepadProps> = ({ gId: pGid, bId: pBid, isPdf, type }) => {
  const classes = useStyles()
  const { selectedGroupId: sGId, selectedPropertyId: sBId } = useSelector((state) => state.profile)
  const { bId, gId } = useMemo(() => {
    if (pGid && pBid) {
      return { gId: pGid, bId: pBid }
    }
    return { gId: sGId, bId: sBId }
  }, [pBid, pGid, sBId, sGId])
  const params = isPdf ? { pagination: false, perPage: 9999 } : { perPage: 25 }
  return (
    <Box className={classes.list}>
      {/* @ts-ignore */}
      <List filter={{ gId, bId }} actions={<ListAction gId={gId} bId={bId} type={type} />} {...params}>
        <PropertyListContent />
      </List>
    </Box>
  )
}
interface MasterNotepadReportProps {
  gId?: string
  bId?: string
  type?: string
}
const MasterNotepadReport: React.FC<MasterNotepadReportProps> = ({ gId, bId, type: pType }) => {
  const classes = useStyles()

  const [{ type, data }, setState] = useSetState({
    type: pType || '',
    data: [],
    loading: false,
  })
  const onInput = (value: string) => {
    setState({ type: value })
  }
  useEffect(() => {
    localStorage.setItem(
      'DashboardDate',
      JSON.stringify({
        ContentType: type,
      })
    )
  }, [type])
  const name = type === 'ContentProof' ? 'masterNotepadContent' : 'masterNotepadSign'
  const isPdf = !!pType
  return (
    <Box className={classnames(classes.root, { [classes.pdf]: isPdf })}>
      <div className={classes.header}>
        <PanelSelection
          label="Select Proof"
          choices={PROOF}
          value={PROOF.find((item) => item.value === type)}
          onChange={(item) => onInput(item?.value as string)}
          popupIcon={<ExpandMore />}
          // width={'100%'}
          height={56}
        />
      </div>

      {type ? (
        <Resource name={name} key={name} list={<MasterNotepad bId={bId} gId={gId} isPdf={isPdf} type={type} />} />
      ) : (
        <Typography className={classes.select}>Please select proof</Typography>
      )}
    </Box>
  )
}

export default MasterNotepadReport
