import React from 'react'
import { SelfieForm, FieldForm } from '@activate-inc/activate-ui'
import { makeStyles } from '@mui/styles'
import { EntityITF } from '@/types/entity'
import { QRCodeFunctionType } from '@/types'
import { Field } from '@activate-inc/activate-ui/dist/types/components/types'
import Config from '@constant/config'

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      minHeight: '100%',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
  }
})

interface Props {
  data?: Field
  entity?: EntityITF | null
  functionalityType: QRCodeFunctionType
  selectedPropertyName?: string
  qrTypeName?: string
  EnableSelfie?: boolean
}
const getClass = () => {
  return new Array(5).fill(0).map((_, index) => {
    const item = `Class of ${2023 + index}`
    return {
      Label: item,
      Text: {
        en: item,
        es: item,
      },
      Value: item,
    }
  })
}

export const CHECK_IN_STUDENT_FIELD = [
  {
    Code: 'TextDisplay',
    FieldText: {
      en: 'Please enter your \ninformation',
      es: 'Please enter your \ninformation',
    },
    ValueType: 'Text',
    FieldType: 'Name',
    FieldStyle: 'TextDisplay',
    style: { marginTop: '29px' },
  },
  {
    Required: true,
    Code: 'Name',
    FieldText: {
      en: 'Name',
      es: 'Name',
    },
    ValueType: 'Text',
    FieldType: 'Name',
    FieldStyle: 'Text',
  },
  {
    Required: true,
    Code: 'PhoneNumber',
    FieldText: {
      en: 'Mobile',
      es: 'Mobile',
    },
    ValueType: 'Text',
    FieldType: 'PhoneNumber',
    FieldStyle: 'Text',
    Disabled: true,
  },
  {
    Required: true,
    Code: 'Email',
    FieldText: {
      en: 'Email',
      es: 'Email',
    },
    ValueType: 'Text',
    FieldType: 'Email',
    FieldStyle: 'Text',
    Choices: [],
    ValueTexts: [],
    ValueChoices: [],
  },
  {
    Required: true,
    Code: 'Class',
    FieldText: {
      en: 'Class',
      es: 'Class',
    },
    FieldStyle: 'DropDownSelection',
    Choices: getClass(),
  },
] as Field[]

export const CHECK_IN_GUEST_FIELD = [
  {
    Code: 'TextDisplay',
    FieldText: {
      en: 'Please enter your \ninformation',
      es: 'Please enter your \ninformation',
    },
    ValueType: 'Text',
    FieldType: 'Name',
    FieldStyle: 'TextDisplay',
    style: { marginTop: '29px' },
  },
] as Field[]
export const CHECK_IN_RESIDENT = [
  {
    Code: 'TextDisplay',
    FieldText: {
      en: 'Please input your name and unit number to check-in.',
      es: 'Please input your name and unit number to check-in.',
    },
    ValueType: 'Text',
    FieldType: 'Name',
    FieldStyle: 'TextDisplay',
    style: { marginTop: '29px' },
  },
] as Field[]
const MAP = {
  [QRCodeFunctionType.GuestCheckin]: {
    title: 'Welcome, Guest!',
    checkInForm: CHECK_IN_GUEST_FIELD,
  },
  [QRCodeFunctionType.ResidentCheckIn]: {
    title: 'Welcome, Back!',
    checkInForm: CHECK_IN_RESIDENT,
  },
  [QRCodeFunctionType.StudentCheckin]: {
    title: 'Welcome, Student!',
    checkInForm: CHECK_IN_STUDENT_FIELD,
  },
  [QRCodeFunctionType.RoofAccess]: {
    title: 'Welcome ~',
    checkInForm: CHECK_IN_STUDENT_FIELD,
  },
  [QRCodeFunctionType.TenantHVAC]: {
    title: 'Welcome ~',
    checkInForm: CHECK_IN_STUDENT_FIELD,
  },
  [QRCodeFunctionType.SupplierCheckin]: {
    title: 'Welcome ~',
    checkInForm: CHECK_IN_STUDENT_FIELD,
  },
  [QRCodeFunctionType.RoofAccessCarousel]: {
    title: 'Welcome ~',
    checkInForm: CHECK_IN_STUDENT_FIELD,
  },
}
const language = 'en'

const CheckinWrapper: React.FC<Props> = ({ functionalityType, entity, data, selectedPropertyName, EnableSelfie }) => {
  const classes = useStyles()
  const mapData = MAP[functionalityType] || { checkInForm: data, title: '' }
  if (QRCodeFunctionType.ResidentCheckIn === functionalityType) {
    mapData.title = `Welcome Back to ${selectedPropertyName}`
  }
  const { title, checkInForm } = mapData
  let formData = checkInForm
  if (
    [
      QRCodeFunctionType.GuestCheckin,
      QRCodeFunctionType.TenantPerformance,
      QRCodeFunctionType.ResidentCheckIn,
    ].includes(functionalityType)
  ) {
    formData = checkInForm.concat(data)
  }
  if (EnableSelfie) {
    const btnText = QRCodeFunctionType.ResidentCheckIn === functionalityType ? 'RESIDENT CHECK-IN' : undefined
    return (
      <SelfieForm
        btnText={btnText}
        title={title}
        onChange={() => {}}
        pageTitle={selectedPropertyName}
        className={classes.container}
      />
    )
  }
  console.log('formData', formData)
  return (
    <FieldForm
      disabledCard
      className={classes.container}
      data={formData}
      returnValueKey
      showHelp
      pageTitle={selectedPropertyName}
      propertyName={selectedPropertyName}
      language={language}
      btnText="CHECK-IN"
      btnWidth="100%"
      onSubmit={() => {}}
      activateTerms={Config.activateTerms}
      terms={'black'}
    />
  )
}

export default CheckinWrapper
