import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import WithDefaultData from '@/hoc/WithDefaultData'
import DragFormPanel from '@component/Form/Form/DragFormPanel'
import UploadFile from '@component/Form/UploadFile'
import { FormControlLabel, Switch } from '@mui/material'
import useSetState from '@hooks/useSetState'
import { QRCodeFunctionType } from '@/types'
import { isCanSavaQr } from '@/utils'
import { FieldItem } from '@utils/FieldType'

const language = 'en'
const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px',
    },
    upload: {
      marginTop: 20,
    },
    switch: {
      alignSelf: 'self-start',
      marginLeft: 0,
      marginTop: 20,
    },
  }
})
const EidtForm = [QRCodeFunctionType.GuestCheckin, QRCodeFunctionType.TourCheckin]

interface Props {
  value: any
  groupId: string
  buildingId: string
  functionType: QRCodeFunctionType
  onChange?: (data: any) => void
  disabledEdit?: boolean
}

const SupplierCheckin: React.FC<Props> = ({
  value,
  groupId,
  buildingId,
  onChange,
  functionType,
  disabledEdit,
  ...extra
}) => {
  const classes = useStyles()
  const [data, setData] = useSetState({
    TermConditionUrl: value?.TermConditionUrl,
    EnableSignature: value?.EnableSignature,
    EnableSelfie: value?.EnableSelfie === undefined ? true : value?.EnableSelfie,
    CookieLess: !!value?.CookieLess,
    Fields: value?.Fields || [],
  })
  const isShowSignature = [QRCodeFunctionType.RoofAccess, QRCodeFunctionType.TenantHVAC].includes(functionType)
  const isHiddenCookie = [QRCodeFunctionType.RoofAccess, QRCodeFunctionType.TenantHVAC].includes(functionType)
  const isHiddenSelfie = [QRCodeFunctionType.ResidentCheckIn].includes(functionType)
  const isShowField =
    functionType &&
    [
      QRCodeFunctionType.StudentCheckin,
      QRCodeFunctionType.GuestCheckin,
      QRCodeFunctionType.TourCheckin,
      QRCodeFunctionType.ResidentCheckIn,
    ].includes(functionType)
  let onlyMoveArr = ['Q001', 'Q002', 'Q003']
  if (functionType === QRCodeFunctionType.TourCheckin) {
    onlyMoveArr = []
  }

  const { TermConditionUrl, EnableSignature, EnableSelfie, Fields, CookieLess } = data
  const onChangeFile = useCallback(
    (file: string) => {
      const newData = Object.assign({}, value, data, { TermConditionUrl: file, CanSave: true })
      setData({ TermConditionUrl: file })
      onChange?.(newData)
    },
    [onChange, setData, value, data]
  )
  const onChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const name = event.target.name
    const newData = Object.assign({}, value, data, { [name]: checked })
    Object.assign(newData, { CanSave: isCanSavaQr(functionType, newData) })
    //@ts-ignore
    setData({ [name]: checked })
    onChange?.(newData)
  }

  const onChangeField = useCallback(
    (fields: FieldItem[]) => {
      const newData = Object.assign({}, value, data, { Fields: fields || [] })
      Object.assign(newData, { CanSave: isCanSavaQr(functionType, newData) })
      onChange?.(newData)
      setData({ Fields: fields || [] })
    },
    [functionType, onChange, setData, value, data]
  )

  useEffect(() => {
    if (!value?.CanSave && !EidtForm.includes(functionType)) {
      onChange?.(Object.assign({ Fields: [] }, value, { CanSave: true }))
    }
  }, [onChange, value, functionType])
  return (
    <div className={classes.container}>
      {isShowField && (
        <DragFormPanel
          language={language}
          data={Fields}
          disabledEdit={!EidtForm.includes(functionType) || disabledEdit}
          hiddenAddBtn={functionType === QRCodeFunctionType.ResidentCheckIn}
          onChangeField={onChangeField}
          onlyMoveArr={onlyMoveArr}
        />
      )}
      {![QRCodeFunctionType.TourCheckin, QRCodeFunctionType.ResidentCheckIn].includes(functionType) && (
        <UploadFile
          className={classes.upload}
          value={TermConditionUrl}
          filePath={`Teams/${groupId}/${buildingId}`}
          onChange={onChangeFile}
          disabledInput
          label="Terms & Conditions (optional)"
        />
      )}
      {isShowSignature && (
        <FormControlLabel
          className={classes.switch}
          control={<Switch checked={EnableSignature} onChange={onChangeSwitch} name="EnableSignature" />}
          label="Finger Signature"
          labelPlacement="start"
        />
      )}
      {!isHiddenSelfie && (
        <FormControlLabel
          className={classes.switch}
          control={<Switch checked={EnableSelfie} onChange={onChangeSwitch} name="EnableSelfie" />}
          label="Selfie"
          labelPlacement="start"
        />
      )}
      {!isHiddenCookie && (
        <FormControlLabel
          className={classes.switch}
          control={<Switch checked={CookieLess} onChange={onChangeSwitch} name="CookieLess" />}
          label="No Cookie"
          labelPlacement="start"
        />
      )}
    </div>
  )
}

export default WithDefaultData(SupplierCheckin)
export const SupplierCheckinNoDefault = SupplierCheckin
